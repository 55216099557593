// Common
export const VERSION = "2.1.6"
export const AUTH_TOKEN = 'auth-token'
export const USER_DATA = 'user-data'
export const REMEMBER_ME = 'remember-me'
export const BUNDLED_PRODUCTS = 'bundled-products'

// Barcodescanner app
export const BEEP_ENABLED = 'beep_enabled'

// Shopify
//export const SHOPIFY_GRAPHQL_ENDPOINT = ".myshopify.com/admin/api/2020-10/graphql.json"
//export const SHOPIFY_SHOP_NAME = 'shopify-shop-name'
//export const SHOPIFY_API_KEY = 'shopify-api-key'
//export const SHOPIFY_PASSWORD = 'shopify-password'

// Environment specific constants
export const env = process.env.NODE_ENV

//export const BACKURI = env === 'production' ? 'http://127.0.0.1:1880/' : 'http://127.0.0.1:1880/'
export const BACKURI = 'http://localhost:1880/'

// Sounds
export const soundCongratzUrl = "https://commondatastorage.googleapis.com/codeskulptor-assets/jump.ogg"
export const soundErrorUrl = "https://rpg.hamsterrepublic.com/wiki-images/7/72/Metal_Hit.ogg"
export const soundBeepUrl = "https://soundbible.com/grab.php?id=1682&type=mp3" //"http://soundbible.com/grab.php?id=1815&type=wav"

// Pictures
export const imageCongratulationsUrl = "https://pngimg.com/uploads/printer/printer_PNG7733.png" // "https://media.tenor.com/images/3eeea7661a451e7dc2e7571384946d28/tenor.gif" //
export const imagePostmanUrl = "https://media.tenor.com/images/81cfd86ea30c519556f5fabbf08febcf/tenor.gif"
export const imageOrderNotFoundUrl = "https://c.tenor.com/0dnJMzleV9UAAAAj/shk-simple-happy-kitchen.gif"
export const imageMaximumreachedUrl = "https://c.tenor.com/qwD7MNBjOwAAAAAj/ditdang-dangheo.gif"
export const imageNoArticle = "https://c.tenor.com/rVe1UKTKhokAAAAj/what-huh.gif"
export const imageLoadingUrl = "https://icon-library.com/images/loading-icon-transparent-background/loading-icon-transparent-background-18.jpg"
export const imageMoney = "https://c.tenor.com/CBq6wEVQnuwAAAAj/purse-people.gif"
export const imageDontKnow = "https://media.tenor.com/images/a5174811bc5c10109b5c85c9de5f9dfd/tenor.gif"
export const imageDeepBreath = "https://media.tenor.com/images/952c121233a34c0807a36a8db5ba5d56/tenor.gif"
export const imageBobCrying = "https://media.tenor.com/images/db363e808d165b096d77dc73fffa5eb8/tenor.gif"
export const imagePrintingLabel = "https://media.tenor.com/images/e5bb0b3e3b9ad3a453ff9c740f53d094/tenor.gif" // "https://media.tenor.com/images/896e55d978f0d28fc6519a1b930348dc/tenor.gif" // "https://media.tenor.com/images/c016d80a6fc0e00157cc8f547ddd0067/tenor.gif"
export const image404 = "https://c.tenor.com/KLM8keUQpSwAAAAj/google-error404robot-error404.gif"
export const imageShipping = "https://c.tenor.com/D3YrBJ7fLW0AAAAj/nox-stapler.gif"

// Url custom
export const completeOrderUrl = "/versand"
