import React from 'react'
import { useSelector } from "react-redux"

// Utils
import { fullCountry } from "../utils/functions"
import { EUCountry } from "../utils/functions"



function MessageBox() {

	const { shippingAddress, number, sellerComment, billbeeShopId } =
		useSelector(store => store.packageAssemblyReducer)

    // const bubbleChat = (comment) => {
    //     return (
    //         <div>
    //             {comment.Name}
    //                 <p className="bubblechat">
    //                     {comment.Text}
    //                 </p>
    //         </div>
    //     )
    // }

    var achtung = "Achtung: EU-Exportsendung"
    if (number && !number.startsWith("EU") && shippingAddress && (shippingAddress.Country === "CH" || shippingAddress.Country === "LI")) {
        achtung = "Achtung: Exporto Sendung"
    }

    return(
        <div>
            {sellerComment && (
                <div className="row m-b-10">
                    <span className="col" style={{"color": "tomato",  "fontSize": "130%", "fontWeight": "bold", "lineHeight": "100%"}}> 
                        {`${sellerComment}`}
                    </span>
                </div>
            )}

            {shippingAddress && (EUCountry(shippingAddress.Country) === shippingAddress.Country) && (
                <div className="row m-b-10">
                    <span className="col" id="achtung" style={{"color": "tomato",  "fontSize": "130%", "fontWeight": "bold"}}> 
                        {achtung}
                    </span>
                </div>
            )}

            {billbeeShopId && billbeeShopId === 75701 && (
                <div className="row m-b-10">
                    <span className="col" style={{"color": "tomato",  "fontSize": "130%", "fontWeight": "bold"}}> 
                        Buchhandelbestellung: Rechnung ausdrucken und mitschicken
                    </span>
                </div>
            )}

            {/* {comments && comments.map(comment => bubbleChat(comment))} */}

        </div>
    )
}

export default MessageBox
